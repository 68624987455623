import "./index.css";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import Loadingspinner from "../LoadingProgressIndicator";
import ConferenceView from "../ConferenceView";
import ConferenceServices from "../../services/ConferenceServices";
import useThrottle from "../../hooks/useThrottle";
import { useAuth0 } from "@auth0/auth0-react";
import SessionStorage from "../../util/SessionStorage";

function ConferenceGridView(props) {
  const [conferencelist, setConferencelist] = useState([]);
  const [load, setLoad] = useState(true);
  const hootconfig = useSelector((state) => state.hootConfig);
  const streamRef = useRef(null);
  const [page, setPage] = useState(1);
  const [isDataExist, setIsDataExist] = useState(true);
  const intervalRef = useRef(null);
  const { isLoading, getIdTokenClaims } = useAuth0();

  const handleInfiniteScrolling = useThrottle(() => {
    const container = streamRef.current;
    if (container) {
      if (
        isDataExist &&
        container.scrollTop + container.clientHeight >=
          container.scrollHeight - 1
      ) {
        setPage((prev) => prev + 1);
      }
    }
  }, 750);

  useEffect(() => {
    const container = streamRef.current;
    container?.addEventListener("scroll", handleInfiniteScrolling);
    return () => {
      container?.removeEventListener("scroll", handleInfiniteScrolling);
    };
  }, [handleInfiniteScrolling]);

  const fetchConferenceData = useCallback(
    async (pageToFetch) => {
      try {
        const res = await ConferenceServices.getConferenceData(
          props.invokedConference,
          SessionStorage.getSessionStorageValue("user_uuid"),
          getIdTokenClaims,
          hootconfig,
          pageToFetch,
          true
        );
        if (res.data && res.data.length !== 0) {
          setConferencelist((prev) => {
            const existingIds = new Set(
              prev.map((item) => item.conference?.id)
            );
            const newConferences = res.data.filter(
              (item) => !existingIds.has(item.conference?.id)
            );
            return [...newConferences, ...prev];
          });
        } else {
          setIsDataExist(false);
        }
      } catch (e) {
        console.log(
          "Unexpected error occurred while getting conference data: ",
          e
        );
      }
      setLoad(false);
    },
    [props.invokedConference, getIdTokenClaims, hootconfig]
  );

  useEffect(() => {
    if (isLoading) return;
    fetchConferenceData(page);
  }, [page, isLoading, fetchConferenceData]);

  // Fetch data every 30 seconds
  useEffect(() => {
    if (isLoading) return;
    intervalRef.current = setInterval(() => {
      fetchConferenceData(1);
    }, 30000);

    return () => clearInterval(intervalRef.current);
  }, [isLoading, fetchConferenceData]);

  if (conferencelist.length === 0 || load)
    return (
      <div className="conference-grid-view inactive">
        {conferencelist.length === 0 && load && (
          <Loadingspinner customClassName="loading-spinner" />
        )}
        {conferencelist.length === 0 &&
          !load &&
          props.invokedConference === "favourite" && (
            <p className="conference-para">You have no favourite conferences</p>
          )}
        {conferencelist.length === 0 &&
          !load &&
          props.invokedConference === "lastjoined" && (
            <p className="conference-para">
              You havent joined any conference recently
            </p>
          )}
        {conferencelist.length === 0 &&
          !load &&
          props.invokedConference === "invited" && (
            <p className="conference-para">
              You havent been invited to any conference
            </p>
          )}
      </div>
    );
  else
    return (
      <div className="conference-grid-view" ref={streamRef}>
        {conferencelist != null &&
          conferencelist.map((item) => (
            <ConferenceView
              key={item.conference.id}
              item={item}
              type={
                props.invokedConference === "lastjoined"
                  ? "lastjoined"
                  : "invited"
              }
              deleteFunction={props.deleteFunction}
              checkForFavourites={props.checkForFavourites}
              getConferenceUsers={props.getConferenceUsers}
              showInvite={props.showInvite}
            />
          ))}
      </div>
    );
}

export default ConferenceGridView;
