import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import Loadingspinner from "../../../../components/LoadingProgressIndicator";
import ConferenceView from "../../../../components/ConferenceView";
import "./index.css";
import { useAuth0 } from "@auth0/auth0-react";
import ConferenceServices from "../../../../services/ConferenceServices";
import useThrottle from "../../../../hooks/useThrottle";

function ConferenceStream(props) {
  const [conferencelist, setConferencelist] = useState([]);
  const [load, setLoad] = useState(true);
  const hootconfig = useSelector((state) => state.hootConfig);
  const { isLoading, getIdTokenClaims } = useAuth0();
  const [page, setPage] = useState(1);
  const streamRef = useRef(null);
  const [isDataExist, setIsDataExist] = useState(true);
  const intervalRef = useRef(null);

  const handleInfiniteScrolling = useThrottle(() => {
    const container = streamRef.current;
    if (container) {
      if (
        isDataExist &&
        container.scrollTop + container.clientHeight >=
          container.scrollHeight - 1
      ) {
        setPage((prev) => prev + 1);
      }
    }
  }, 750);

  useEffect(() => {
    const container = streamRef.current;
    container?.addEventListener("scroll", handleInfiniteScrolling);
    return () => {
      container?.removeEventListener("scroll", handleInfiniteScrolling);
    };
  }, [handleInfiniteScrolling]);

  const fetchConferenceData = useCallback(
    async (pageToFetch) => {
      try {
        const res = await ConferenceServices.getConferenceData(
          props.invokedConference,
          props.userUUID,
          getIdTokenClaims,
          hootconfig,
          pageToFetch
        );
        if (res.data && res.data.length !== 0) {
          setConferencelist((prev) => {
            const existingIds = new Set(
              prev.map((item) => item.conference?.id)
            );
            const newConferences = res.data.filter(
              (item) => !existingIds.has(item.conference?.id)
            );
            return [...newConferences, ...prev];
          });
        } else {
          setIsDataExist(false);
        }
      } catch (e) {
        console.log(
          "Unexpected error occurred while getting conference data: ",
          e
        );
      }
      setLoad(false);
    },
    [props.invokedConference, props.userUUID, getIdTokenClaims, hootconfig]
  );

  useEffect(() => {
    if (isLoading) return;
    fetchConferenceData(page);
  }, [page, isLoading, fetchConferenceData]);

  // Fetch data every 30 seconds
  useEffect(() => {
    if (isLoading) return;
    intervalRef.current = setInterval(() => {
      fetchConferenceData(1);
    }, 30000);

    return () => clearInterval(intervalRef.current);
  }, [isLoading, fetchConferenceData]);

  // console.log("Conference list is: ", conferencelist);

  return (
    <div
      className={
        conferencelist.length === 0
          ? "conference-stream inactive"
          : "conference-stream"
      }
      ref={streamRef}
    >
      {conferencelist.length === 0 && load && (
        <Loadingspinner customClassName="loading-spinner" />
      )}
      {conferencelist &&
        conferencelist.length === 0 &&
        !load &&
        props.invokedConference === "lastjoined" && (
          <p className="conference-para">You have no recent conferences</p>
        )}
      {conferencelist &&
        conferencelist.length === 0 &&
        !load &&
        props.invokedConference !== "lastjoined" && (
          <p className="conference-para">
            You have no conferences you've been invited to
          </p>
        )}
      {conferencelist &&
        conferencelist.length > 0 &&
        !load &&
        conferencelist.map((item) => {
          // console.log("Items is: ", item);
          return (
            <ConferenceView
              key={item.conference?.id}
              item={item}
              type={
                props.invokedConference === "lastjoined"
                  ? "lastjoined"
                  : "invited"
              }
              deleteFunction={props.deleteFunction}
              checkForFavourites={props.checkForFavourites}
              getConferenceUsers={props.getConferenceUsers}
              showInvite={props.showInvite}
            />
          );
        })}
    </div>
  );
}

export default ConferenceStream;
