import { createStore } from "redux";
import {persistStore,persistReducer} from "redux-persist"
// import {persistReducer} from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage"
import rootReducer from "./RootReducer";
import { getDefaultMiddleware } from '@reduxjs/toolkit';


const persistConfig={
   key: 'persist-key',
   storage
 }

const persistReducerConfig=persistReducer(persistConfig,rootReducer)

const store = createStore(persistReducerConfig,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
// store.subscribe(() => {
//    //console.log(store.getState());
// });

const persistor= persistStore(store)

export default store;
export {persistor}
