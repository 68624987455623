import { useCallback, useRef } from "react";

function useThrottle(callback, delay) {
  const lastCall = useRef(null);
  const timeout = useRef(null);
  const throttledCallback = useCallback(
    (...args) => {
      const now = Date.now();
      if (lastCall.current && now < lastCall.current + delay) {
        if (timeout.current) {
          clearTimeout(timeout.current);
        }
        timeout.current = setTimeout(() => {
          lastCall.current = now;
          callback(...args);
        }, delay - (now - lastCall.current));
      } else {
        lastCall.current = now;
        callback(...args);
      }
    },
    [callback, delay]
  );
  return throttledCallback;
}

export default useThrottle;
