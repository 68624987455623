import "./App.css";
import { useDispatch } from "react-redux";
import { setConfigDetails } from "./stateManagement/reducer/HootConfig";
import Apihelper from "./services/ApiHelper";
import AppRoutes from "./routes";
// import { getBrowserToken,messaging,getConferenceIdByTopicId} from "./util/Firebase";
import { useEffect } from "react";
// import {moveConference, receiveMessage} from './stateManagement/actions'
// import {onMessage } from "firebase/messaging";
import speakerbusIcon from "./assets/sbIconTitle.png";

function App(props) {
  const dispatch = useDispatch();
  dispatch(setConfigDetails(props.config));
  Apihelper.setbaseUrl(props.config.app.api.base_url);
  Apihelper.setLimit(props.config.app.api.limit);
  useEffect(() => {
    if (props.config.name === "Speakerbus") {
      document.title = "Speakerbus-CHEIMS";
      const linkElement = document.head.querySelector("link[rel='icon']");
      linkElement.href = speakerbusIcon;
    }
  }, [dispatch]);
  const setUserDetails = (userDetails) => {
    dispatch(setUserDetails(userDetails));
  };
  return (
    <div>
      <AppRoutes />
    </div>
  );
}

export default App;
